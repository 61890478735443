
import React from 'react';
import '../../src/skins.css';
import { ReactComponent as CSharp } from '../img/csharp.svg';
import { ReactComponent as JQuery } from '../img/jquery.svg';
import { ReactComponent as DotNet } from '../img/NET.svg';
import { ReactComponent as MySql}  from '../img/mysql.svg';
import { ReactComponent as Tailwind} from '../img/tailwindcss.svg';
function Skins() {

    return (
        <div className='cntn justify-content-center'>
            <div className='container justify-content-center'>
                <div className='row mt-5 justify-content-center'>
                    <h2 className='gradient-text'>Skill`s</h2>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-md-2 col-5 container-skin' title='HTML'>
                                <a href='https://certificados.programadorbr.com/certificado/DVWBDO14HT21082' target='_blanck'>
                                    <div className='m-2 icon'><i style={{color: '#dd4b25'}} class="fa-brands fa-html5"></i></div>
                                </a>
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='CSS'>
                                <a href='https://certificados.programadorbr.com/certificado/DVWBDO14HT21082' target='_blanck'>
                                    <div className='m-2 icon'><i style={{color: '#1664c0'}} class="fa-brands fa-css3-alt"></i></div>
                                </a>    
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='PHP'>
                                <div className='m-2 icon'><i style={{color: '#7377ad'}} class="fa-brands fa-php"></i></div>
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='JavaScript'>
                                <a href='https://certificados.programadorbr.com/certificado/DVWBDO14HT21082' target='_blanck' >
                                    <div className='m-2 icon'><i style={{color: '#e2c60b'}} class="fa-brands fa-js"></i></div>
                                </a>    
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='C#'>
                                <a href='https://hermes.dio.me/certificates/HHI3GAR4.pdf' target='_blanck'>
                                    <CSharp className='mt-2' width="45" height="45" />
                                </a>    
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='Laravel'>
                                <div className='m-2 icon'><i style={{color: 'red'}} class="fa-brands fa-laravel"></i></div>
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='ReactJS'>
                                <a href='https://certificados.programadorbr.com/certificado/DVWBDJDOF31RE21067' target='_blanck'>
                                    <div className='m-2 icon'><i style={{color: '#087ea4'}} class="fa-brands fa-react"></i></div>
                                </a>    
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='Vue.JS'>
                                <div className='m-2 icon'><i style={{color: '#42b883'}} class="fa-brands fa-vuejs"></i></div>
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='JQuery'>
                                <JQuery width="80" height="80" />
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='.NET'>
                                <a href='https://hermes.dio.me/certificates/HHI3GAR4.pdf' target='_blanck'>
                                    <DotNet className='mt-3' width="40" height="40" />
                                </a>    
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='Bootstrap e Tailwind'>
                                <div className='m-2 icon'>
                                    <i style={{color: '#563d7c'}} class="fa-brands fa-bootstrap"></i>
                                    <Tailwind style={{marginLeft: 10}} width="40" height="40" />
                                </div>
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='MySQL'>
                                <MySql className='mt-2' width="60" height="60" />
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='Docker'>
                                <a href='https://hermes.dio.me/certificates/CLXAZSDN.pdf' target='_blanck'>
                                    <div className='m-2 icon'><i style={{color: '#1d63ed'}} class="fa-brands fa-docker"></i></div>
                                </a>    
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='GIT' >
                                <div className='m-2 icon'><i style={{color: '#e84d31'}} class="fa-brands fa-git-alt"></i></div>
                            </div>
                            <div className='col-md-2 col-5 container-skin' title='Jira e Trello'>
                                <div className='m-2 icon'>
                                    <i style={{color: '#0556cc', margin: 10}} class="fa-brands fa-jira"></i>
                                    <i style={{color: '#0556cc'}} class="fa-brands fa-trello"></i>
                                </div>
                            </div>
                            { /* 
                            <div className='col-md-2 col-5 container-skin' title='Jira'>
                                <div className='m-2 icon'><i style={{color: '#0556cc'}} class="fa-brands fa-jira" ></i></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Skins;
